<template>
  <div class="content"> 
    <div class="top">
      <a-input
        placeholder="id或标题或正确答案"
        v-model="params.title"
        @pressEnter="search"
        @change="valueNull"
      />
      <a-select  allowClear v-model="params.degree" size='small' style="width:100px;margin:5px;" placeholder='等级'>
          <a-select-option :value='1'>一级</a-select-option>
          <a-select-option :value='2'>二级</a-select-option>
          <a-select-option :value='3'>三级</a-select-option>
          <a-select-option :value='4'>四级</a-select-option>
      </a-select>

      <button class="search" @click="search">搜索</button>
      <button class="add" size="large" style="width:90px" @click="openModal()">添加问题</button>

    </div>
    <div class="table-content">
      <a-table
        :columns="list"
        :data-source="data"
        rowKey="id"
        @change="changePage"
        :pagination="pagination"
      >
        <div slot="showImage" slot-scope="imageUrl" style="width:50px;height:50px;display:flex;justify-content:center;align-items:center;">
            <img @click="previewImg(imageUrl)" style='max-width:50px;max-height:50px;' :src='imageUrl'/>
        </div>

        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>



    <a-modal centered :width='700' v-model="visible" @ok='checkForms' @cancel='cancel'>
        <div style="height:700px;overflow-y:auto;">
            <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                <a-form-model-item label='问题等级' prop='degree'>
                    <a-select allowClear  @change="changeSelect" v-model="forms.degree" size='small' style="width:200px;margin:5px;" placeholder='问题等级'>
                      <a-select-option :value='1'>初级</a-select-option>
                      <a-select-option :value='2'>中级</a-select-option>
                      <a-select-option :value='3'>高级</a-select-option>
                      <a-select-option :value='4'>王者级</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label='标题' prop='title'>
                    <a-input placeholder='标题' v-model="forms.title"></a-input>
                </a-form-model-item>

                <a-form-model-item label='选项A' prop='chooseAnswerA'>
                    <a-input placeholder='选项A' v-model="forms.chooseAnswerA"></a-input>
                </a-form-model-item>
                <a-form-model-item label='选项B' prop='chooseAnswerB'>
                    <a-input placeholder='选项B' v-model="forms.chooseAnswerB"></a-input>
                </a-form-model-item>
                <a-form-model-item label='选项C' prop='chooseAnswerC'>
                    <a-input placeholder='选项C' v-model="forms.chooseAnswerC"></a-input>
                </a-form-model-item>
                <a-form-model-item label='选项D' prop='chooseAnswerD'>
                    <a-input placeholder='选项D' v-model="forms.chooseAnswerD"></a-input>
                </a-form-model-item>
                <a-form-model-item label='正确选项' prop='realAnswer'>
                    <a-input placeholder='正确选项' v-model="forms.realAnswer"></a-input>
                </a-form-model-item>
                <a-form-model-item label='尺寸' prop='size'>
                    <a-input placeholder='尺寸' v-model="forms.size"></a-input>
                </a-form-model-item>

                <a-form-model-item label='展示图' prop='showImageUrl'>
                    <a-input v-model="forms.showImageUrl" allowClear @paste.stop.prevent="untils.pasteImg($event,1,parsetCallback)">
                        <a-upload
                            slot='addonBefore'
                            name="file"
                            :showUploadList='false'
                            :action="ip+'/file/upload'"
                            @change="handleChange($event,forms,1)">
                            <a> <a-icon type="upload"/></a>
                        </a-upload>
                    </a-input>
                </a-form-model-item>

                <a-form-model-item label='答案图' prop='revealImageUrl'>
                    <a-input v-model="forms.revealImageUrl" allowClear @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback)">
                        <a-upload
                            slot='addonBefore'
                            name="file"
                            :showUploadList='false'
                            :action="ip+'/file/upload'"
                            @change="handleChange($event,forms,2)">
                            <a> <a-icon type="upload"/></a>
                        </a-upload>
                    </a-input>
                </a-form-model-item>
           
            </a-form-model>
        </div>
    </a-modal>

  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {quillEditor} from 'vue-quill-editor'
import quillConfig from '../../../../untils/qull.config'
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  components: {
    quillEditor,
  },

  computed:{
    ...mapState(['ip']),
  },

  
  data() {
    return {
      // editorOption: {
      //   placeholder: "编辑文章内容",
      // },
      innerTitle: "123",
      visible: false,
     
      list: [
        { title: "序号", dataIndex: "id", align: "center" },
        {title: "标题",align: "center",dataIndex: "title",},
        {title: "展示图",dataIndex: "showImageUrl", align: "center", scopedSlots: { customRender: "showImage" },},
        {title: "答案图",dataIndex: "revealImageUrl", align: "center", scopedSlots: { customRender: "showImage" },},
        {title: "选项A",align: "center",dataIndex: "chooseAnswerA",},
        {title: "选项B",align: "center",dataIndex: "chooseAnswerB",},
        {title: "选项C",align: "center",dataIndex: "chooseAnswerC",},
        {title: "选项D",align: "center",dataIndex: "chooseAnswerD",},
        {title: "正确答案",align: "center",dataIndex: "realAnswer",},
        {title:'等级',dataIndex:'degree',key:'degree',
            customRender:(text)=>{
                const inner = text
                if(inner == 1){
                    return <span style="color:green">一级</span>
                }
                if(inner == 2){
                    return <span style="color:red">二级</span>
                }
                if(inner == 3){
                    return <span style="color:red">三级</span>
                }
                if(inner == 4){
                    return <span style="color:red">四级</span>
                }
            }
        },
        {title: "创建时间",align: "center",dataIndex: "createTime",},
        {title: "行情Id",align: "center",dataIndex: "relationGoodsId",},
        {title: "操作",align: "center",scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { pageNum: 1, pageSize: 10, title: "",degree:"" },
      forms:{
        // showImageUrl:'',
        // revealImageUrl:'',
        
      },
      rules:{
          degree:[{ required: true, message: '等级', trigger: 'change' }],
          chooseAnswerA:[{ required: true, message: '选项', trigger: 'blur' }],
          chooseAnswerB:[{ required: true, message: '选项', trigger: 'blur' }],
          chooseAnswerC:[{ required: true, message: '选项', trigger: 'blur' }],
          chooseAnswerD:[{ required: true, message: '选项', trigger: 'blur' }],
          realAnswer:[{ required: true, message: '选项', trigger: 'blur' }],
          showImageUrl:[{ required: true, message: '图标', trigger: 'blur' }],
          revealImageUrl:[{ required: true, message: '图标', trigger: 'blur' }],
      },
      layout:{
          labelCol: { span:3},//标签占2份  共24份
          wrapperCol: { span:21},
      },
      searchInpt: "",
    };
  },
  methods: {
    getList(params) {
      this.axios("/dq_admin/gameQuestion/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },

    changePage(page) {
      const { current, pageSize } = page;

      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },

    changeSelect(e){
        this.forms.degree = e
    },

    previewImg(imageUrl){
        if(imageUrl&&imageUrl.length>0){
            this.$previewImg({
                list:[{img_url:imageUrl}],
                baseImgField:'img_url',
                showMute:false,
            })
        }
    },

    cancel(){
        this.forms = {};
        this.$refs.ruleForm.resetFields();
    },

    checkForms(){
        this.$refs.ruleForm.validate(valid => {
            if(valid){
                this.submit(this.forms)
            }else{
                return false;
            }
        });
    },
    async submit(){
        const res = await this.axios.post('/dq_admin/gameQuestion/addOrEdit',this.forms)
        if(res.status == 200){
            const {message} = res
            this.$message.success(message)
            this.getList(this.params)
            this.visible = false
            this.cancel()
        }
    },

    parsetCallback(type,result){
      if(type == 1){
        this.forms.showImageUrl = result
      }
      if(type == 2){
        this.forms.revealImageUrl = result
      }
      // this.$forceUpdate()
    },

    handleChange(res,forms,type){
        const {response} = res.file
        const {data} = response || {data:null}
        if(data){
          if(type == 1){
            this.$set(this.forms,'showImageUrl');
            this.forms.showImageUrl = data
          }
          if(type == 2){
            this.$set(this.forms,'revealImageUrl');
            this.forms.revealImageUrl = data
          }
        }
    },

    search(e) {
      this.params.page = 1;
        this.getList(this.params);
        this.pagination.current = 1;
    },
    
    valueNull() {
      // if (this.searchInpt == "") {
        
      // }

      this.getList(this.params);
      this.pagination.current = 1;
    },
    openModal(row) {
      if (row) {
        this.visible = true;
        this.forms = JSON.parse(JSON.stringify(row));
      } else {
        this.openType = 2;
        this.visible = true;
        this.innerTitle = "新增问题";
      }
    },

    remove(id) {
      this.axios("/dq_admin/gameQuestion/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);
        this.$message.success("删除成功");
      });
    },

    hideModal() {
      if (this.featureTitle == "") {
        this.$message.warning("请输入币种特征");
      } else if (this.coinKindId == "") {
        this.$message.warning("请选择币种");
      } else {
        if (this.openType == 1) {
          this.axios
            .post("/dq_admin/coinFeature/edit", {
              featureTitle: this.featureTitle,
              sort: this.sort,
              featureType: this.featureType,
              level:this.level,
              coinKindId: this.coinKindId,
              id:this.id,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("修改成功");
            });
        } else {
          this.axios
            .post("/dq_admin/coinFeature/add", {
              featureTitle: this.featureTitle,
              sort: this.sort,
              featureType: this.featureType,
              level:this.level,
              coinKindId: this.coinKindId,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("新增成功");
            });
        }
      }
    },

    resetForms() {
    
      // this.coin_feature_list = [];
      // this.featureData = [{sort:""}];
    },
    addFeature(){
      this.featureData.push({})
    },
    removeare(index){
      this.featureData.splice(index,1)
    },
    onChangeChecked(event,index) {
      this.featureData[index].ids = event;
    },
  },
  mounted() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss" scoped>
.content{display: flex;flex-direction: column;
    .top-search{display: flex;align-items: center;}
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}

.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
</style>
