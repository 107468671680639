var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            attrs: { placeholder: "id或标题或正确答案" },
            on: { pressEnter: _vm.search, change: _vm.valueNull },
            model: {
              value: _vm.params.title,
              callback: function($$v) {
                _vm.$set(_vm.params, "title", $$v)
              },
              expression: "params.title"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { width: "100px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "等级" },
              model: {
                value: _vm.params.degree,
                callback: function($$v) {
                  _vm.$set(_vm.params, "degree", $$v)
                },
                expression: "params.degree"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("一级")]),
              _c("a-select-option", { attrs: { value: 2 } }, [_vm._v("二级")]),
              _c("a-select-option", { attrs: { value: 3 } }, [_vm._v("三级")]),
              _c("a-select-option", { attrs: { value: 4 } }, [_vm._v("四级")])
            ],
            1
          ),
          _c("button", { staticClass: "search", on: { click: _vm.search } }, [
            _vm._v("搜索")
          ]),
          _c(
            "button",
            {
              staticClass: "add",
              staticStyle: { width: "90px" },
              attrs: { size: "large" },
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_vm._v("添加问题")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.list,
              "data-source": _vm.data,
              rowKey: "id",
              pagination: _vm.pagination
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "showImage",
                fn: function(imageUrl) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "50px",
                          "max-height": "50px"
                        },
                        attrs: { src: imageUrl },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(imageUrl)
                          }
                        }
                      })
                    ]
                  )
                }
              },
              {
                key: "name",
                fn: function(text) {
                  return _c("a", {}, [_vm._v(_vm._s(text))])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要删除此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 700 },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "700px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "问题等级", prop: "degree" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px", margin: "5px" },
                          attrs: {
                            allowClear: "",
                            size: "small",
                            placeholder: "问题等级"
                          },
                          on: { change: _vm.changeSelect },
                          model: {
                            value: _vm.forms.degree,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "degree", $$v)
                            },
                            expression: "forms.degree"
                          }
                        },
                        [
                          _c("a-select-option", { attrs: { value: 1 } }, [
                            _vm._v("初级")
                          ]),
                          _c("a-select-option", { attrs: { value: 2 } }, [
                            _vm._v("中级")
                          ]),
                          _c("a-select-option", { attrs: { value: 3 } }, [
                            _vm._v("高级")
                          ]),
                          _c("a-select-option", { attrs: { value: 4 } }, [
                            _vm._v("王者级")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "标题" },
                        model: {
                          value: _vm.forms.title,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "title", $$v)
                          },
                          expression: "forms.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "选项A", prop: "chooseAnswerA" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "选项A" },
                        model: {
                          value: _vm.forms.chooseAnswerA,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "chooseAnswerA", $$v)
                          },
                          expression: "forms.chooseAnswerA"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "选项B", prop: "chooseAnswerB" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "选项B" },
                        model: {
                          value: _vm.forms.chooseAnswerB,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "chooseAnswerB", $$v)
                          },
                          expression: "forms.chooseAnswerB"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "选项C", prop: "chooseAnswerC" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "选项C" },
                        model: {
                          value: _vm.forms.chooseAnswerC,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "chooseAnswerC", $$v)
                          },
                          expression: "forms.chooseAnswerC"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "选项D", prop: "chooseAnswerD" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "选项D" },
                        model: {
                          value: _vm.forms.chooseAnswerD,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "chooseAnswerD", $$v)
                          },
                          expression: "forms.chooseAnswerD"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "正确选项", prop: "realAnswer" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "正确选项" },
                        model: {
                          value: _vm.forms.realAnswer,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "realAnswer", $$v)
                          },
                          expression: "forms.realAnswer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "尺寸", prop: "size" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "尺寸" },
                        model: {
                          value: _vm.forms.size,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "size", $$v)
                          },
                          expression: "forms.size"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "展示图", prop: "showImageUrl" } },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: { allowClear: "" },
                          on: {
                            paste: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.untils.pasteImg(
                                $event,
                                1,
                                _vm.parsetCallback
                              )
                            }
                          },
                          model: {
                            value: _vm.forms.showImageUrl,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "showImageUrl", $$v)
                            },
                            expression: "forms.showImageUrl"
                          }
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                slot: "addonBefore",
                                name: "file",
                                showUploadList: false,
                                action: _vm.ip + "/file/upload"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChange($event, _vm.forms, 1)
                                }
                              },
                              slot: "addonBefore"
                            },
                            [
                              _c(
                                "a",
                                [_c("a-icon", { attrs: { type: "upload" } })],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "答案图", prop: "revealImageUrl" } },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: { allowClear: "" },
                          on: {
                            paste: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.untils.pasteImg(
                                $event,
                                2,
                                _vm.parsetCallback
                              )
                            }
                          },
                          model: {
                            value: _vm.forms.revealImageUrl,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "revealImageUrl", $$v)
                            },
                            expression: "forms.revealImageUrl"
                          }
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                slot: "addonBefore",
                                name: "file",
                                showUploadList: false,
                                action: _vm.ip + "/file/upload"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChange($event, _vm.forms, 2)
                                }
                              },
                              slot: "addonBefore"
                            },
                            [
                              _c(
                                "a",
                                [_c("a-icon", { attrs: { type: "upload" } })],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }